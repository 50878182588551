
// Make it simple to swap parts of a URL attribute on an element
function updateAttributeURL(element, attr, swapOut, swapIn) {
  var url = element.getAttribute(attr);
  url = url.replace(swapOut, swapIn);
  // element.setAttribute(attr, url);
  element.setAttribute(attr, swapIn);
  console.log('swapping done', {attr, url, swapOut, swapIn})
}

// Update the image source on elements in the picture element
function loadImage(picture) {

  var sources = picture.children;
  var loadingPath = "images/tiny";
  // var sizes = ["large","medium","small"];

  for(var s=0; s<sources.length; s++) {
    const srcPath = sources[s].dataset.src
    console.log({srcPath})

    // update the src or srcSet urls
    if (sources[s].hasAttribute("srcSet")) {
      // updateAttributeURL(sources[s], "srcSet", loadingPath, "images/"+sizes[s] );
      console.log('picture')
      updateAttributeURL(sources[s], "srcSet", loadingPath, srcPath );
    } else {
      // updateAttributeURL(sources[s], "src", loadingPath, "images/"+sizes[s] );
      console.log('img')
      updateAttributeURL(sources[s], "src", loadingPath, srcPath );
    }

    // remove the lazy-initial class when the full image is loaded to unblur
    sources[s].addEventListener('load', image => {
      // image.target.closest("picture").classList.remove("lazy-initial")
      image.target.closest("picture").classList.remove("lazy")
    }, false);
  }

}


// Stop observing this image and load its source
function lazyLoad(elements) {
  elements.forEach(item => {
    if (item.intersectionRatio > 0) {
      observer.unobserve(item.target);
      loadImage(item.target);
    };
  });
};

// Set up the intersection observer to detect when to define
// and load the real image source
var options = {
  rootMargin: "100px",
  threshold: 1.0
};
var observer = new IntersectionObserver(lazyLoad, options);

export function enableLazyLoadPictures (){
  // Watch for all pictures with a "lazy" class
  var pictures = document.querySelectorAll('picture.lazy');
  pictures.forEach(pic => {
    observer.observe(pic);
  });
}

