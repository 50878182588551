import React from "react";

const Home = () => (
  <div>
    <p className="App-intro">
    Not found
    </p>
  </div>
);

export default Home;
